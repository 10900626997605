import PersonImg1 from '../../../../images/documents-page/lawyers/JakubDąbroś.png';
import PersonImg2 from '../../../../images/documents-page/lawyers/JakubKocioł.png';
import PersonImg3 from '../../../../images/documents-page/lawyers/JakubGerula.png';
import PersonImg4 from '../../../../images/documents-page/lawyers/AleksandraPenkowska.png';
import PersonImg5 from '../../../../images/documents-page/lawyers/KarolWitas.png';
import PersonImg6 from '../../../../images/documents-page/lawyers/MichałPrzychoda.png';
import PersonImg7 from '../../../../images/documents-page/lawyers/MateuszCzajkowski.png';
import PersonImg8 from '../../../../images/documents-page/lawyers/TomaszBajarbaatar.png';
import PersonImg9 from '../../../../images/documents-page/lawyers/KatarzynaKopeć.png';
import PersonImg10 from '../../../../images/documents-page/lawyers/KatarzynaWójcik.png';
import PersonImg11 from '../../../../images/documents-page/lawyers/MaciejZadja.png';
import PersonImg12 from '../../../../images/documents-page/lawyers/CelejRafał.png';
import PersonImg13 from '../../../../images/documents-page/lawyers/DawidMalinowski.png';
import PersonImg14 from '../../../../images/documents-page/lawyers/KonradTrzyna.png';
import PersonImg15 from '../../../../images/documents-page/lawyers/NataliaCichocka-Stępień.png';
import PersonImg16 from '../../../../images/documents-page/lawyers/WojciechKalinowski.png';
import PersonImg17 from '../../../../images/documents-page/lawyers/ArkadiuszKlenowski.png';
import PersonImg18 from '../../../../images/documents-page/lawyers/MichałWołangiewicz.png';

export const lawyers = [
  {
    name: 'Jakub Dąbroś',
    positionPl: 'Wiceprezes | Fundacja Innovations Hub',
    positionEn: 'Vice President | Fundacja Innovations Hub',
    image: PersonImg1
  },
  {
    name: 'Jakub Kozioł',
    positionPl: 'Managing Partner | The Heart Legal',
    positionEn: 'Managing Partner | The Heart Legal',
    image: PersonImg2
  },
  {
    name: 'Jakub Gerula',
    positionPl: 'Associate | Linklaters',
    positionEn: 'Associate | Linklaters',
    image: PersonImg3
  },
  {
    name: 'Aleksandra Penkowska',
    positionPl: 'Partner | SKplus+',
    positionEn: 'Partner | SKplus+',
    image: PersonImg4
  },
  {
    name: 'Karol Witas',
    positionPl: 'Associate | The Heart Legal',
    positionEn: 'Associate | The Heart Legal',
    image: PersonImg5
  },
  {
    name: 'Dr Michał Przychoda',
    positionPl: 'Counsel | DZP Domański Zakrzewski Palinka',
    positionEn: 'Counsel | DZP Domański Zakrzewski Palinka',
    image: PersonImg6
  },
  {
    name: 'Mateusz Czajkowski',
    positionPl: 'Legal Partner | Warsaw Equity Group',
    positionEn: 'Legal Partner | Warsaw Equity Group',
    image: PersonImg7
  },
  {
    name: 'Tomasz Bajarbaatar',
    positionPl: 'Adwokat | Warsaw Equity Group',
    positionEn: 'Lawyer | Warsaw Equity Group',
    image: PersonImg8
  },
  {
    name: 'Katarzyna Kopeć-Tokarzewska',
    positionPl: 'Head of Legal & Compliance | Everfield',
    positionEn: 'Head of Legal & Compliance | Everfield',
    image: PersonImg9
  },
  {
    name: 'Katarzyna Wójcik',
    positionPl: 'Associate | DZP Domański Zakrzewski Palinka',
    positionEn: 'Associate | DZP Domański Zakrzewski Palinka',
    image: PersonImg10
  },
  {
    name: 'Maciej Zajda',
    positionPl: 'Partner | DZP Domański Zakrzewski Palinka',
    positionEn: 'Partner | DZP Domański Zakrzewski Palinka',
    image: PersonImg11
  },
  {
    name: 'Rafał Celej',
    positionPl: 'Partner | Kondracki Celej',
    positionEn: 'Partner | Kondracki Celej',
    image: PersonImg12
  },
  {
    name: 'Dawid D. Malinowski',
    positionPl: 'Corporate Senior Associate | Bexley Beaumont',
    positionEn: 'Corporate Senior Associate | Bexley Beaumont',
    image: PersonImg13
  },
  {
    name: 'Konrad Trzyna',
    positionPl: 'Partner | Hard2Beat & Shape VC',
    positionEn: 'Partner | Hard2Beat & Shape VC',
    image: PersonImg14
  },
  {
    name: 'Natalia Cichocka-Stępień',
    positionPl: 'Associate | DLA Piper',
    positionEn: 'Associate | DLA Piper',
    image: PersonImg15
  },
  {
    name: 'Wojciech Kalinowski',
    positionPl: 'Counsel | DLA Piper',
    positionEn: 'Counsel | DLA Piper',
    image: PersonImg16
  },
  {
    name: 'Arkadiusz Klejnowski',
    positionPl: 'Senior Associate | Kondracki Celej',
    positionEn: 'Senior Associate | Kondracki Celej',
    image: PersonImg17
  },
  {
    name: 'Michał Wołangiewicz',
    positionPl: 'Senior Associate | Linklaters',
    positionEn: 'Senior Associate | Linklaters',
    image: PersonImg18
  }
];
