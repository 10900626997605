import styled from 'styled-components';

export const ModelDocumentsContainer = styled.div`
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  position: relative;

  .circle-image {
    position: absolute !important;
    top: 0px;
    right: 0px;
    img {
      width: 300px;
    }
  }
  @media only screen and (max-width: 1000px) {
    .circle-image {
      display: none;
    }
  }
`;

export const UpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 580px;
`;

export const DownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    margin-right: auto;

    p {
      margin-right: auto;
    }
  }
`;

export const FilterButton = styled.button`
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--UI-Light-Blue, #4ebfee);
  background: var(--UI-Blue-Tint, #f5fafd);

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;

  &:hover {
    border: 1px solid var(--UI-Light-Blue, #4ebfee);
    background: rgba(78, 191, 238, 0.2);
  }

  @media only screen and (max-width: 700px) {
    /* flex-direction: column; */
    margin-right: auto;
  }
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const DocumentComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(5, 28, 38, 0.2);
  box-sizing: border-box;
  width: 100%;
  /* transition: max-height 1s ease-in-out; */
  /* max-height: 1000px; */
  /* margin-bottom: 16px; */

  &.hidden {
    /* max-height: 0; */
    /* margin-bottom: 0; */
    /* overflow: hidden; */
    display: none;
  }
`;

export const UpDocument = styled.div`
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border-radius: 8px;
  background: var(--UI-Blue-Tint, #f5fafd);
  cursor: pointer;

  svg {
    padding: 8px;
    transition: transform 0.3s ease-in-out;
  }
  .rotate-svg {
    transform: rotate(180deg);
    transform-origin: center;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  @media only screen and (max-width: 700px) {
    p {
      margin: auto;
    }
  }
`;

export const MiddleContentUp = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const LanguageComponent = styled.div`
  display: flex;
  padding: 4px 16px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  background: var(--M3-white, #fff);
`;

export const LanguageParagraph = styled.p`
  color: var(--UI-Light-Blue, #4ebfee);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.7px;
  margin: 0;

  .flag {
    vertical-align: middle;
    margin-bottom: 4px;
    img {
      width: 18px;
    }
  }
`;

export const DownloadButton = styled.button`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 5px;
  border: 2px solid var(--UI-Dark-Blue, #0f5575);

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;

  &:hover {
    border: 2px solid var(--UI-Light-Blue, #4ebfee);
    color: #fff;
    background: var(--UI-Light-Blue, #4ebfee);
  }

  &.disable {
    pointer-events: none;
    background: #dddddd;
    border: 2px solid #dddddd;
    color: #ffffff !important;
  }
`;

export const BottomDocument = styled.div`
  display: flex;
  padding: 16px 200px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;

  @media only screen and (max-width: 700px) {
    padding-right: 16px !important;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const TagComponent = styled.div`
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--UI-Blue-Tint, #f5fafd);

  color: var(--UI-Light-Blue, #4ebfee);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
`;
