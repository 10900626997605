import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { H2Style } from '../../styled.components';
import {
  OrganizationsContainer,
  OrganizationsContent
} from './styled.components';
import { StaticImage } from 'gatsby-plugin-image';

const OrganizationsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <OrganizationsContainer>
          <H2Style color="#0F5575">{t`documents-page.organizations-title`}</H2Style>
          <OrganizationsContent>
            <a
              href="https://uprp.gov.pl/pl"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                className="organization-image"
                src="../../../../images/documents-page/organizations/uprp.webp"
                alt="Urząd Patentowy Rzeczypospolitej Polskiej"
                placeholder="Uprp"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.astrazeneca.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                className="organization-image"
                src="../../../../images/documents-page/organizations/astra.webp"
                alt="AstraZeneca"
                placeholder="AstraZeneca"
                loading="lazy"
              />
            </a>
            <a
              href="https://ekipainvestments.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                className="organization-image"
                src="../../../../images/documents-page/organizations/ekipa.webp"
                alt="Ekipa Investments"
                placeholder="Ekipa Investments"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.envirly.com/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                className="organization-image"
                src="../../../../images/documents-page/organizations/envirly.webp"
                alt="Envirly"
                placeholder="Envirly"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.codenauts.io/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                className="organization-image"
                src="../../../../images/documents-page/organizations/codenautus.webp"
                alt="Codenautus"
                placeholder="Codenautus"
                loading="lazy"
              />
            </a>
          </OrganizationsContent>
        </OrganizationsContainer>
      </div>
    </>
  );
};

export default OrganizationsSection;
