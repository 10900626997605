import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  BottomCanvasContainer,
  BottomCanvasTitleSection,
  BottomToDownload,
  DownloadTile,
  H3Container,
  PdfTile,
  PuzzleCanvasContainer,
  SpcContainer,
  SpcDescriptionSection,
  SpcRightContainer,
  SpcWhySection,
  SwitchButton,
  SwitchingButtonsContainer,
  SwitchingDataContainer,
  SwitchingDataLeft,
  SwitchPuzzleContainer,
  ToDownloadContainer,
  UpCanvasContainer,
  UpCanvasH3,
  UpCanvasTitleSection,
  UpToDownload
} from './styled.components';
import {
  H2Style,
  H3Style,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle
} from '../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'react-i18next';
import DocumentsModal from '../../../../components/modals/documents/DocumentsModal';

const PuzzleCanvasSection = ({ downloadFile }) => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState('Base');
  const [currentData, setCurrentData] = useState();

  const [isDocumentExpand, setIsDocumentExpand] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [multipleDocuments, setMultipleDocuments] = useState(false);

  const views = [
    {
      id: 'Base',
      title: t`documents-page.puzzle-canvas-base-title`,
      description: (
        <Trans
          i18nKey="documents-page.puzzle-canvas-base-description"
          components={{
            b: <b />
          }}
        />
      ),
      image: (
        <StaticImage
          class="right-image"
          src="../../../../images/documents-page/puzzle-canvas/base.webp"
          alt="Base"
          loading="lazy"
        />
      ),
      documents: [
        {
          node: {
            title: 'Base with guidelines',
            file: {
              file: {
                url: 'https://downloads.ctfassets.net/d4b060axqd4d/7osXGNU9xTQkzhb4zkdhrk/c58517f4497923e58d4c7255ff3b443f/Base_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Base blanc',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/5KIdsCpmzNo0Ai6oasYBFY/3d6cec4007cd0a3829f28173270ba553/Base_blanc.pdf'
              }
            }
          }
        }
      ]
    },
    {
      id: 'Puzzle',
      title: t`documents-page.puzzle-canvas-puzzle-title`,
      description: (
        <Trans
          i18nKey="documents-page.puzzle-canvas-puzzle-description"
          components={{
            b: <b />
          }}
        />
      ),
      image: (
        <StaticImage
          class="right-image"
          src="../../../../images/documents-page/puzzle-canvas/puzzle.webp"
          alt="Puzzle"
          loading="lazy"
        />
      ),
      documents: [
        {
          node: {
            title: 'IP puzzle with guidelines',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/2hqv6OzLJHETSrFXtmJbMQ/808a6f5f16f65675edef4fc505e6461c/IP_puzzle_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'IP puzzle blanc',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/3QMgAxoY511x3Ge0kIUr6D/1029a33e3f42580420917eed3a8028fd/IP_puzzle_blanc.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Software puzzle with guidelines',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/4pKFQeq9QIRH0JszUl4Vwu/19b944a9625c504e7e3291f69d070500/Software_puzzle_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Software puzzle blanc',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/5P5xjcF0c4sEJ08KvGPQIW/c1cfd0f0cae6f59f95aafa902f56ac36/Software_puzzle_blanc.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Carbon Footprint puzzle with guidelines',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/3TJHwo3giv5hobXh7dnQ96/f5fbdd4b7f4d683dc5d702d6853f38ac/Carbon_Footprint_puzzle_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Carbon Footprint puzzle blanc',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/25t279KebnpW1CxEl2lPEk/ad26890ab794bed67850a1efad4b1c36/Carbon_Footprint_puzzle_blanc.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Raising VC with guidelines',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/3BTKJ2PFQzKO2Sr2UlB4UV/d8c94bdcab7496c5302c9b7abad5d82a/Raising_VC_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Raising VC blanc',
            file: {
              file: {
                url: 'https://assets.ctfassets.net/d4b060axqd4d/28p6SMARpm7CSqhBVMTnOC/0a6a6d4f7486adcde19a2df0ef22059c/Raising_VC_blanc.pdf'
              }
            }
          }
        }
      ]
    },
    {
      id: 'Overlays',
      title: t`documents-page.puzzle-canvas-overlays-title`,
      description: (
        <Trans
          i18nKey="documents-page.puzzle-canvas-overlays-description"
          components={{
            b: <b />
          }}
        />
      ),
      image: (
        <StaticImage
          class="right-image"
          src="../../../../images/documents-page/puzzle-canvas/overlays.webp"
          alt="Overlays"
          loading="lazy"
        />
      ),
      documents: [
        {
          node: {
            title: 'Pharmacy overlay with guidelines',
            file: {
              file: {
                url: 'https://downloads.ctfassets.net/d4b060axqd4d/28VL4AzBelIfmTb6bVp8yc/fc22e0b6273143708df4d8f7ad1c4e9d/Pharmacy_overlay_with_guidelines.pdf'
              }
            }
          }
        },
        {
          node: {
            title: 'Pharmacy overlay blanc',
            file: {
              file: {
                url: 'https://downloads.ctfassets.net/d4b060axqd4d/2m5mP1rhLEl2Eq2tZhzZ4w/0b4909ee83d5f19fd492bdaedba67b72/Pharmacy_overlay_blanc.pdf'
              }
            }
          }
        }
      ]
    }
  ];

  useEffect(() => {
    const filterCurrentView = views.find((view) => {
      return view.id === currentView;
    });
    setCurrentData(filterCurrentView);
  }, [currentView]);

  useEffect(() => {
    if (currentData) {
      if (currentData.documents.length > 4) {
        setIsDocumentExpand(false);
      } else {
        setIsDocumentExpand(true);
      }
    }
  }, [currentData]);

  const openModal = (document) => {
    setMultipleDocuments(false);
    setCurrentDocument(document);
    setIsModalOpen(true);
  };

  const openModalWithMultipleData = (documents) => {
    setMultipleDocuments(true);
    setCurrentDocument(documents);
    setIsModalOpen(true);
  };

  return (
    <>
      <DocumentsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfDocument={currentDocument}
        multipleDocuments={multipleDocuments}
      />
      <PuzzleCanvasContainer>
        <div className="container">
          <UpCanvasContainer>
            <UpCanvasTitleSection>
              <H2Style color="#0F5575">{t`documents-page.puzzle-canvas-title`}</H2Style>
              <UpCanvasH3 color="#0F5575">{t`documents-page.puzzle-canvas-title2`}</UpCanvasH3>
            </UpCanvasTitleSection>
            <SpcContainer>
              <StaticImage
                className="puzzle-canvas-image"
                src="../../../../images/documents-page/puzzle-canvas/documents-puzzle-canvas.webp"
                alt="Why image"
                placeholder="none"
                loading="lazy"
              />
              <SpcRightContainer>
                <SpcDescriptionSection>
                  <H3Style color="#0F5575">{t`documents-page.puzzle-canvas-spc-title`}</H3Style>
                  <ParagraphMediumStyle>
                    <Trans
                      i18nKey="documents-page.puzzle-canvas-spc-description"
                      components={{
                        b: <b />
                      }}
                    />
                  </ParagraphMediumStyle>
                </SpcDescriptionSection>
                <SpcDescriptionSection>
                  <SpcWhySection>
                    <ParagraphLargeStyle color="#5FA5C5">{t`documents-page.puzzle-canvas-why-title`}</ParagraphLargeStyle>
                    <H3Style color="#0F5575">{t`documents-page.puzzle-canvas-why-title2`}</H3Style>
                  </SpcWhySection>
                  <ParagraphMediumStyle>{t`documents-page.puzzle-canvas-why-description`}</ParagraphMediumStyle>
                </SpcDescriptionSection>
              </SpcRightContainer>
            </SpcContainer>
          </UpCanvasContainer>
          <BottomCanvasContainer>
            <BottomCanvasTitleSection>
              <ParagraphLargeStyle color="#5FA5C5">{t`documents-page.puzzle-canvas-bottom-title`}</ParagraphLargeStyle>
              <H3Container>
                <H3Style color="#0F5575">{t`documents-page.puzzle-canvas-bottom-title2`}</H3Style>
                <H3Style color="#0F5575">{t`documents-page.puzzle-canvas-bottom-title3`}</H3Style>
              </H3Container>
            </BottomCanvasTitleSection>
            <SwitchPuzzleContainer>
              <SwitchingButtonsContainer>
                <SwitchButton
                  onClick={() => setCurrentView('Base')}
                  className={
                    currentView === 'Base' ? 'checked' : ''
                  }>{t`documents-page.puzzle-canvas-base`}</SwitchButton>
                <SwitchButton
                  onClick={() => setCurrentView('Puzzle')}
                  className={
                    currentView === 'Puzzle' ? 'checked' : ''
                  }>{t`documents-page.puzzle-canvas-puzzle`}</SwitchButton>
                <SwitchButton
                  onClick={() => setCurrentView('Overlays')}
                  className={
                    currentView === 'Overlays' ? 'checked' : ''
                  }>{t`documents-page.puzzle-canvas-overlays`}</SwitchButton>
              </SwitchingButtonsContainer>
              {currentData && (
                <SwitchingDataContainer>
                  <SwitchingDataLeft>
                    <H3Style color="#0F5575">{currentData.title}</H3Style>
                    <ParagraphMediumStyle>
                      {currentData.description}
                    </ParagraphMediumStyle>
                    <ToDownloadContainer>
                      <UpToDownload
                        className={
                          currentData.documents.length > 4
                            ? ''
                            : 'disable-clicking'
                        }
                        onClick={() => {
                          if (currentData.documents.length > 4) {
                            setIsDocumentExpand(!isDocumentExpand);
                          }
                        }}>
                        {currentData.documents.length > 4 && (
                          <svg
                            className={isDocumentExpand ? 'rotate-svg' : ''}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M18 9L12 15L6 9"
                              stroke="#5FA5C5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                        <H4Style color="#080E14">{t`documents-page.puzzle-canvas-download`}</H4Style>
                        <div class="tooltip">
                          <span class="tooltiptext">{t`documents-page.puzzle-canvas-download-all`}</span>
                          <svg
                            onClick={(event) => {
                              event.stopPropagation();
                              openModalWithMultipleData(currentData.documents);
                            }}
                            className="download-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none">
                            <g clip-path="url(#clip0_4434_261)">
                              <path
                                d="M4.5 17.5V19.5C4.5 20.0304 4.71071 20.5391 5.08579 20.9142C5.46086 21.2893 5.96957 21.5 6.5 21.5H18.5C19.0304 21.5 19.5391 21.2893 19.9142 20.9142C20.2893 20.5391 20.5 20.0304 20.5 19.5V17.5"
                                stroke="#0F5575"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.5 11.5L12.5 16.5L17.5 11.5"
                                stroke="#0F5575"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5 4.5V16.5"
                                stroke="#0F5575"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4434_261">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0.5 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </UpToDownload>
                      {isDocumentExpand && (
                        <BottomToDownload>
                          {currentData.documents.map((document) => (
                            <DownloadTile>
                              <PdfTile>PDF</PdfTile>
                              <ParagraphMediumStyle>
                                {document.node.title}
                              </ParagraphMediumStyle>
                              <svg
                                onClick={(event) => {
                                  event.stopPropagation();
                                  openModal(document);
                                }}
                                className="download-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none">
                                <g clip-path="url(#clip0_4434_2695)">
                                  <path
                                    d="M4.5 17.5V19.5C4.5 20.0304 4.71071 20.5391 5.08579 20.9142C5.46086 21.2893 5.96957 21.5 6.5 21.5H18.5C19.0304 21.5 19.5391 21.2893 19.9142 20.9142C20.2893 20.5391 20.5 20.0304 20.5 19.5V17.5"
                                    stroke="#5FA5C5"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.5 11.5L12.5 16.5L17.5 11.5"
                                    stroke="#5FA5C5"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.5 4.5V16.5"
                                    stroke="#5FA5C5"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4434_2695">
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="white"
                                      transform="translate(0.5 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </DownloadTile>
                          ))}
                        </BottomToDownload>
                      )}
                    </ToDownloadContainer>
                  </SwitchingDataLeft>
                  {currentData.image}
                </SwitchingDataContainer>
              )}
            </SwitchPuzzleContainer>
          </BottomCanvasContainer>
        </div>
      </PuzzleCanvasContainer>
    </>
  );
};

export default PuzzleCanvasSection;
