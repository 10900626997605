import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import HeroSection from './components/hero-section';
import WhySection from './components/why-section';
import FirstTutorialSection from './components/first-tutorial-section';
import LawyersSection from './components/lawyers-section';
import { graphql, useStaticQuery } from 'gatsby';
import getCurrentTranslations from '../../components/contentful-translator';
import ModelDocumentsSection from './components/model-documents-section';
import PuzzleCanvasSection from './components/puzzle-canvas-section';
import OrganizationsSection from './components/organizations-section';

const DocumentsPage = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);
  const data = useStaticQuery(graphql`
    query {
      allContentfulDocuments(sort: { fields: createdAt }) {
        edges {
          node {
            title
            whoseDocument
            categories
            language
            source
            tags
            description
            file {
              file {
                url
              }
            }
            showForm
            node_locale
          }
        }
      }
    }
  `);

  const [modelDocuments, setModelDocuments] = useState();
  const [tutorial, setTutorial] = useState();

  const downloadFile = async (downloadedFile) => {
    try {
      const response = await fetch(downloadedFile.node.file.file.url);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadedFile.node.title.replace(' ', '_');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => {
    const getDocuments = () => {
      const getDocuments = getCurrentTranslations(
        data.allContentfulDocuments.edges,
        language
      );
      const findModelDocuments = getDocuments.filter((document) => {
        return (
          document.node.categories.includes('Startupy') ||
          document.node.categories.includes('Inwestorzy') ||
          document.node.categories.includes('Inkubatory') ||
          document.node.categories.includes('Polski standard')
        );
      });
      setModelDocuments(findModelDocuments);

      const firstTutorial = getDocuments.find((tutorial) =>
        tutorial.node.categories.includes('Poradnik')
      );
      setTutorial(firstTutorial);
    };
    getDocuments();
  }, [data.allContentfulDocuments, language]);

  return (
    <Layout withHeader={false}>
      <Seo
        title={t`documents-page.seo-title`}
        description={t`documents-page.seo-description`}
      />
      <HeroSection />
      <WhySection />
      <div id="tutorial">
        {tutorial && (
          <FirstTutorialSection
            tutorial={tutorial}
            downloadFile={downloadFile}
          />
        )}
      </div>
      <div id="authors">
        <LawyersSection />
      </div>
      <div id="modal-documents">
        {modelDocuments && (
          <ModelDocumentsSection
            modelDocuments={modelDocuments}
            downloadFile={downloadFile}
          />
        )}
      </div>
      <PuzzleCanvasSection downloadFile={downloadFile} />
      <OrganizationsSection />
    </Layout>
  );
};

export default DocumentsPage;
