import styled from 'styled-components';

export const OrganizationsContainer = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`;

export const OrganizationsContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;

  .organization-image {
    img {
      width: 180px;
    }
  }
`;
