import React, { useContext } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  DataContainer,
  LawyersContainer,
  PersonContainer,
  PersonImage,
  PersonTextContainer
} from './styled.components';
import {
  H2Style,
  H3Style,
  H4Style,
  ParagraphSmallStyle
} from '../../styled.components';
import { lawyers } from './lawyers-table';

const LawyersSection = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const renderLawyers = lawyers.map((lawyer) => (
    <PersonContainer key={lawyer.name}>
      <PersonImage backgroundImage={lawyer.image} />
      <PersonTextContainer>
        <H4Style color="#0F5575">{lawyer.name}</H4Style>
        <ParagraphSmallStyle color="#0F5575">
          {language === 'pl' ? lawyer.positionPl : lawyer.positionEn}
        </ParagraphSmallStyle>
      </PersonTextContainer>
    </PersonContainer>
  ));

  return (
    <>
      <LawyersContainer>
        <div className="container">
          <H2Style color="#0F5575">{t`documents-page.lawyers-title`}</H2Style>
          <DataContainer>{renderLawyers}</DataContainer>
          {/* <H3Style color="#0F5575">{t`documents-page.soon`}</H3Style> */}
        </div>
      </LawyersContainer>
    </>
  );
};

export default LawyersSection;
