import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  BottomDocument,
  DocumentComponent,
  DocumentsContainer,
  DownContainer,
  DownloadButton,
  FilterButton,
  FilterContainer,
  LanguageComponent,
  LanguageParagraph,
  MiddleContent,
  MiddleContentUp,
  ModelDocumentsContainer,
  TagComponent,
  TagContainer,
  UpContainer,
  UpDocument
} from './styled.components';
import {
  H2Style,
  H3Style,
  H4Style,
  ParagraphMediumStyle,
  ParagraphSmallStyle
} from '../../styled.components';
import DocumentsModal from '../../../../components/modals/documents/DocumentsModal';
import { StaticImage } from 'gatsby-plugin-image';

const ModelDocumentsSection = ({ modelDocuments, downloadFile }) => {
  const { t } = useTranslation();
  const [modelDocumentsData, setModelDocuments] = useState(modelDocuments);
  const [modelDocumentsStates, setModelDocumentsStates] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModelDocument, setCurrentModelDocument] = useState(null);

  const shownNumber = 6;
  const [shownModelDocuments, setShownModelDocuments] = useState(shownNumber);

  useEffect(() => {
    setModelDocuments(modelDocuments);
    setModelDocumentsStates(modelDocuments.map(() => false));
  }, [modelDocuments]);

  const FILTER_OPTIONS = {
    Startupy: 'Startupy',
    Inwestorzy: 'Inwestorzy',
    Inkubatory: 'Inkubatory',
    'Polski standard': 'Polski standard'
  };

  const toggleFilter = (filter) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter)
        : [...prevFilters, filter]
    );
  };

  const filteredDocuments =
    selectedFilters.length === 0
      ? modelDocumentsData
      : modelDocumentsData.filter((doc) =>
          doc.node.categories.some((cat) => selectedFilters.includes(cat))
        );

  const openModal = (modelDocument) => {
    if (modelDocument.node.showForm) {
      setCurrentModelDocument(modelDocument);
      setIsModalOpen(true);
    } else {
      downloadFile(modelDocument);
    }
  };

  const showSomeModelDocuments = () => {
    if (shownModelDocuments < filteredDocuments.length) {
      setShownModelDocuments(shownModelDocuments + shownNumber);
    } else {
      setShownModelDocuments(shownNumber);
    }
  };

  const renderModelDocuments = filteredDocuments?.map(
    (modelDocument, index) => {
      const isExpand = modelDocumentsStates?.[index] || false;

      const changeView = () => {
        setModelDocumentsStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = !newStates[index];
          return newStates;
        });
      };

      return (
        <DocumentComponent
          className={`${index < shownModelDocuments ? '' : 'hidden'}`}
          key={modelDocument.node.title}>
          <UpDocument onClick={changeView}>
            <svg
              className={isExpand ? 'rotate-svg' : ''}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M18 9L12 15L6 9"
                stroke="#5FA5C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <MiddleContent>
              <MiddleContentUp>
                <H4Style color="#080E14">{modelDocument.node.title}</H4Style>
                <LanguageComponent>
                  <LanguageParagraph>
                    {modelDocument.node.language}{' '}
                    {modelDocument.node.language === 'Polski' && (
                      <StaticImage
                        className="flag"
                        src="../../../../images/documents-page/model-document/pl.webp"
                        alt="pl"
                        placeholder="pl"
                        loading="lazy"
                      />
                    )}
                    {modelDocument.node.language === 'English' && (
                      <StaticImage
                        className="flag"
                        src="../../../../images/documents-page/model-document/en.webp"
                        alt="en"
                        placeholder="en"
                        loading="lazy"
                      />
                    )}
                  </LanguageParagraph>
                </LanguageComponent>
              </MiddleContentUp>
              <ParagraphMediumStyle color="#5FA5C5">
                {modelDocument.node.whoseDocument}
              </ParagraphMediumStyle>
            </MiddleContent>
            <DownloadButton
              onClick={(event) => {
                event.stopPropagation();
                openModal(modelDocument);
              }}>{t`documents-page.model-document-download`}</DownloadButton>
          </UpDocument>
          <BottomDocument
            style={{
              maxHeight: isExpand ? '500px' : '0',
              padding: isExpand ? '16px 200px 16px 16px' : '0 200px 0 16px'
            }}>
            <ParagraphMediumStyle>
              {modelDocument.node.description}
            </ParagraphMediumStyle>
            <ParagraphMediumStyle color="#0F5575">
              {t`documents-page.model-document-source`}
              {modelDocument.node.source}
            </ParagraphMediumStyle>
            <TagContainer>
              {modelDocument.node.tags.map((tag) => (
                <TagComponent>{tag}</TagComponent>
              ))}
            </TagContainer>
          </BottomDocument>
        </DocumentComponent>
      );
    }
  );

  return (
    <>
      <DocumentsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfDocument={currentModelDocument}
      />
      <div className="container">
        <ModelDocumentsContainer>
          <StaticImage
            className="circle-image"
            src="../../../../images/documents-page/model-document/circles.webp"
            alt="Jakub image"
            placeholder="none"
            loading="lazy"
          />
          <UpContainer>
            <H2Style color="#080E14">{t`documents-page.model-document-title`}</H2Style>
            <ParagraphMediumStyle color="#0F5575">{t`documents-page.model-document-description-1`}</ParagraphMediumStyle>
            <ParagraphMediumStyle color="#0F5575">{t`documents-page.model-document-description-2`}</ParagraphMediumStyle>
          </UpContainer>
          <DownContainer>
            <FilterContainer>
              <ParagraphSmallStyle>{t`documents-page.model-document-filter`}</ParagraphSmallStyle>
              {Object.keys(FILTER_OPTIONS).map((filter) => (
                <FilterButton
                  key={filter}
                  onClick={() => toggleFilter(FILTER_OPTIONS[filter])}
                  style={{
                    backgroundColor:
                      selectedFilters.includes(FILTER_OPTIONS[filter]) &&
                      'rgba(78, 191, 238, 0.2)'
                  }}>
                  {t(
                    `documents-page.model-document-${filter
                      .toLowerCase()
                      .replace(' ', '-')}`
                  )}
                </FilterButton>
              ))}
            </FilterContainer>
            {filteredDocuments.length === 0 && (
              <H3Style color="#0F5575">{t`documents-page.soon`}</H3Style>
            )}
            <DocumentsContainer>
              {modelDocumentsData && renderModelDocuments}
              {filteredDocuments.length > shownNumber && (
                <DownloadButton
                  className={
                    filteredDocuments.length <= shownNumber ? 'disable' : ''
                  }
                  onClick={showSomeModelDocuments}>
                  {shownModelDocuments < filteredDocuments.length
                    ? `${t`documents-page.model-document-show-more`}`
                    : `${t`documents-page.model-document-show-less`}`}
                </DownloadButton>
              )}
            </DocumentsContainer>
          </DownContainer>
        </ModelDocumentsContainer>
      </div>
    </>
  );
};

export default ModelDocumentsSection;
